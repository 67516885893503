import Vue from 'vue';
import store from '@/store';
import axios from 'axios';

const httpClient = axios.create({
    baseURL: "https://api.agencyreporting.com",
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

httpClient.interceptors.request.use(config => {
    return config;
});

httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            return store.dispatch('auth/logout');
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$http_client = httpClient;

export default httpClient;
