<template>
  <div class="layout-navbar" :class="{ 'settings-navbar': $route.name && $route.name.includes('settings') }">
    <div class="container">
      <div class="navbar-content-wrapper">
        <div>
          <div class="navbar-heading" v-html="navbarHeading"></div>

          <div key="dashboard_subheading" v-if="$route.name === 'dashboard'">
            <div class="d-flex align-items-center mt-50">
              <div class="navbar-subheading flex-shrink-0 mr-50"> Show data for:</div>
              <div class="d-flex align-items-center justify-content-start">
                <b-form-select
                    class="displayed-data-select"
                    :options="timeframeOptions"
                    :value="currentTimeframe ? currentTimeframe.id : null"
                    @change="changeCurrentTimeframe"
                />
                <feather-icon icon="ChevronDownIcon" size="18"/>
              </div>
            </div>
            <div v-if="!currentTimeframe" class="displayed-data-range-text">Loading...</div>
            <div v-else class="displayed-data-range-text">{{ currentTimeframe.actual_daterange }}</div>
            <div style="opacity:50%;margin-top:10px;">Data fetched at:
              {{ this.$store.state.auth.user.account.data_last_fetched_at_formatted }}
            </div>
          </div>

          <div key="settings_subheading" class="navbar-subheading font-weight-light"
               v-else-if="isSettingsPage">
            Configure your account settings here and now...
          </div>
        </div>

        <div class="navbar-dropdown-wrapper">
          <b-dropdown no-caret toggle-class="p-0" variant="link" right>
            <template #button-content>
              <button class="navbar-dropdown-btn mr-1">
                <feather-icon icon="SettingsIcon" size="20"/>
              </button>
            </template>

            <template #default>
              <b-dropdown-item
                  @click="$router.push('/settings/locations')"
                  link-class="d-flex align-items-center"
              >
                <feather-icon
                    size="16"
                    icon="MapPinIcon"
                    class="mr-50"
                />
                <span>Location Settings</span>
              </b-dropdown-item>
              <b-dropdown-item
                  @click="$router.push('/settings/billing')"
                  link-class="d-flex align-items-center">
                <feather-icon
                    size="16"
                    icon="CreditCardIcon"
                    class="mr-50"
                />
                <span>Payment Settings</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>

          <button class="navbar-dropdown-btn"

                  @click.prevent="$store.dispatch('auth/logout')"
          >
            <feather-icon icon="LogOutIcon" size="20"/>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {BFormSelect, BDropdown, BDropdownItem, BDropdownDivider, BButton, VBModal} from "bootstrap-vue";

export default {
  name: 'LayoutNavbar',
  components: {
    BButton,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownDivider
  },
  directives: {
    VBModal
  },
  data() {
    return {
      range: 'Last 30 days'
    }
  },
  computed: {
    ...mapState('auth', ['user']),

    ...mapState('dashboard', ['timeframes', 'timeframeOptions', 'currentTimeframe']),

    isSettingsPage() {
      return this.$route.name && this.$route.name.includes('settings');
    },

    navbarHeading() {
      if (this.$route.name === 'dashboard') {
        return `<span class="font-weight-bold">KPI Reporting for</span> ${this.user ? this.user.name : null}`
      }

      if (this.isSettingsPage) {
        return '<span class="font-weight-bold">Settings</span>'
      }


    }
  },
  methods: {
    changeCurrentTimeframe(timeframeId) {
      this.$store.commit('dashboard/SET_CURRENT_TIMEFRAME_BY_ID', timeframeId);
    }
  }
}
</script>
