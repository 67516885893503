import httpClient from '@/client';

export default {
    get: {
        user: () => {
            return httpClient.get('/api/user');
        },
    },
    post: {
        receiveJWT: (email, password) => {
            const formData = new FormData();

            formData.append('grant_type', 'password');
            formData.append('client_id', 2);

            formData.append('client_secret', 'f8bNTbQb0HUxkgv32MSPcZRzgHhIZ6LtiNEG8bou');
            formData.append('username', email);
            formData.append('password', password);

            return httpClient.post('/oauth/token', formData);
        },
        register: (company_name, email, password, ref = null) => {
            return httpClient.post('/api/sign-up', {
                company_name,
                email,
                password,
                ref
            });
        },
        setupApiKeyApiKey(apiKey) {
            return httpClient.post('api/account/api-key', {
                ghl_api_key: apiKey
            });
        },
        sendResetPasswordEmail: (email) => {
            return httpClient.post('api/reset-password', {
                email: email
            });
        },
        logout: () => {
            return httpClient.post('/api/logout');
        },
        updatePassword(token, password, confirmPassword) {
            return httpClient.post('api/change-password', {
                token,
                password,
                password_confirmation: confirmPassword
            });
        }
    }
}
