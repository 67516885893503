import AuthService from '@/services/AuthService';
import httpClient from '@/client';

export default {
    namespaced: true,
    state: {
        user: null,
        accessToken: null
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, accessToken) => {
            state.accessToken = accessToken;
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            localStorage.setItem('access_token', JSON.stringify(accessToken));
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        CLEAR_USER_DATA: () => {
            localStorage.removeItem('access_token');
            if (localStorage.getItem('from_admin_area')) {
                localStorage.removeItem('from_admin_area');
                window.location = 'https://admin.agencyreporting.com/admin/resources/users';
            } else {
                location.reload();
            }
        }
    },
    actions: {
        _setAuthUserData: async ({commit, dispatch, state}, jwt) => {
            commit('SET_ACCESS_TOKEN', jwt);
            const {data: user} = await AuthService.get.user();
            commit('SET_USER', user);
        },
        login: async ({commit, dispatch}, {email, password}) => {
            const {data: {access_token}} = await AuthService.post.receiveJWT(email, password);
            return dispatch('_setAuthUserData', access_token);
        },
        authorizeFromLocalJWT: async ({commit, dispatch}) => {
            let accessToken = localStorage.getItem('access_token');

            if (!accessToken) return;
            return dispatch('_setAuthUserData', JSON.parse(accessToken));
        },
        logout: async ({commit}) => {
            // await AuthService.post.logout();
            commit('CLEAR_USER_DATA');
        },
    },
    getters: {
        isAuth: state => !!state.user,

        hasActiveSubscription: state => {
            if (!state.user) return false;

            return state.user.has_active_subscription;
        },

        hasNeverSubscribed: state => {
            if (!state.user) return false;

            return state.user.has_never_subscribed;
        },

        hasSetupApiKey: state => {
            if (!state.user) return false;

            if (!state.user.account) return false;

            return !!state.user.account.ghl_api_key_configured;
        },

        hasConfiguredLocations: state => {
            if (!state.user) return false;

            if (!state.user.account) return false;

            return !!state.user.account.locations_configured;
        }
    }
}
